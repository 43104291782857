Px.Editor.AdvancedEditPanel = class AdvancedEditPanel extends Px.Editor.BaseComponent {

  template() {
    const r = this.renderChild;
    const store = this.data.store;
    const element = this.data.element;
    const ToggleButton = Px.Components.ToggleButton;
    const Dropdown = Px.Components.Dropdown;
    const SliderWithNumericInput = Px.Components.SliderWithNumericInput;
    const CoordinateInput = Px.Editor.CoordinateInput;

    return Px.template`
      <div class="px-advanced-edit-panel px-edit-panel">
        <div class="px-edit-section">
          <div class="px-edit-controls">
            ${Px.if(element.group, () => {
              return Px.template`
                <div class="px-edit-control px-action-button">
                  <button class="px-small px-secondary-color" data-onclick="ungroupElement">
                    ${Px.t('Ungroup Element')}
                  </button>
                </div>
              `;
            })}

            ${Px.if(element.type !== 'barcode' && element.type !== 'qrcode', () => {
              return Px.template`
                <div class="px-edit-control">
                  <h2>${Px.t('Edit Flags')}</h2>
                  <div class="px-element-edit-flags">
                    ${r(ToggleButton, 'edit-button-${element.unique_id}', this.editButtonProps)}

                    ${Px.if(element.edit, () => {
                      return Px.template`
                        ${r(ToggleButton, 'move-button-${element.unique_id}', this.moveButtonProps)}
                        ${r(ToggleButton, 'resize-button-${element.unique_id}', this.resizeButtonProps)}
                        ${r(ToggleButton, 'delete-button-${element.unique_id}', this.deleteButtonProps)}
                        ${r(ToggleButton, 'rotate-button-${element.unique_id}', this.rotateButtonProps)}
                        ${Px.if(element.hasOwnProperty('eopacity'), () => {
                          return r(ToggleButton, 'opacity-button-${element.unique_id}', this.opacityButtonProps);
                        })}
                        ${Px.if(element.hasOwnProperty('elayer'), () => {
                          return r(ToggleButton, 'layers-button-${element.unique_id}', this.layersButtonProps);
                        })}
                        ${Px.if(element.hasOwnProperty('ecolor'), () => {
                          return r(ToggleButton, 'color-button-${element.unique_id}', this.colorButtonProps);
                        })}
                        ${Px.if(Px.config.image_borders && element.hasOwnProperty('eborder'), () => {
                          return r(ToggleButton, 'border-button-${element.unique_id}', this.borderButtonProps);
                        })}
                        ${Px.if(Px.config.image_borders && element.hasOwnProperty('ebordercolor'), () => {
                          return r(ToggleButton, 'border-color-button-${element.unique_id}', this.borderColorButtonProps);
                        })}
                        ${Px.if(Px.config.border_radius && element.hasOwnProperty('eborderradius'), () => {
                          return r(ToggleButton, 'border-radius-button-${element.unique_id}', this.borderRadiusButtonProps);
                        })}
                        ${Px.if(element.type === 'image', () => {
                          return Px.template`
                            ${r(ToggleButton, 'mask-button-${element.unique_id}', this.maskButtonProps)}
                            ${r(ToggleButton, 'replace-button-${element.unique_id}', this.replaceButtonProps)}
                          `;
                        })}
                        ${Px.if(element.type === 'text', () => {
                          return Px.template`
                            ${r(ToggleButton, 'font-size-button-${element.unique_id}', this.fontSizeButtonProps)}
                            ${r(ToggleButton, 'shrink-button-${element.unique_id}', this.shrinkButtonProps)}
                          `;
                        })}
                      `;
                    })}
                  </div>
                </div>

                ${Px.if(element.type !== 'calendar', () => {
                  return Px.template`
                    <div class="px-edit-control">
                      <h2>${Px.t('Placeholder Flags')}</h2>
                      <div class="px-element-edit-flags">
                        ${r(ToggleButton, 'placeholder-button-${element.unique_id}', this.placeholderButtonProps)}
                        ${Px.if(element.placeholder, () => {
                          return Px.template`
                            ${r(ToggleButton, 'show-on-preview-button-${element.unique_id}', this.showOnPreviewButtonProps)}
                          `;
                        })}
                      </div>
                    </div>
                  `;
                })}
              `;
            })}

            ${Px.if(element.type === 'image' || element.type === 'ipage', () => {
              return Px.template`
                <div class="px-edit-control">
                  <h2>${Px.t('Cropping')}</h2>
                  <div class="px-element-edit-flags">
                    ${r(ToggleButton, 'crop-button-${element.unique_id}', this.cropButtonProps)}
                    ${r(ToggleButton, 'stretch-button-${element.unique_id}', this.stretchButtonProps)}
                  </div>
                </div>
              `;
            })}
            ${Px.if(element.type === 'image', () => {
              return Px.template`
                <div class="px-edit-control">
                  <h2>${Px.t('Mirror Wrap')}</h2>
                  ${r(CoordinateInput, 'borderwrap-input-${element.unique_id}', this.borderwrapInputProps)}
                </div>
              `;
            })}

            <div class="px-edit-control">
              <h2>${Px.t('Position & Size')}</h2>
              <div class="px-element-coords">
                ${r(CoordinateInput, 'x-input-${element.unique_id}', this.xInputProps)}
                ${r(CoordinateInput, 'y-input-${element.unique_id}', this.yInputProps)}
                ${r(CoordinateInput, 'width-input-${element.unique_id}', this.widthInputProps)}
                ${r(CoordinateInput, 'height-input-${element.unique_id}', this.heightInputProps)}
              </div>
            </div>

            ${Px.if(element.type !== 'barcode', () => {
              return Px.template`
                <div class="px-edit-control px-slider-control">
                  <h2>${Px.t('Layer')}</h2>
                  ${r(SliderWithNumericInput, `layer-slider-${element.unique_id}`, this.layerSliderProps)}
                </div>
              `;
            })}

            ${Px.if(element.type === 'image' || element.type === 'text', () => {
              return Px.template`
                <div class="px-edit-control">
                  <h2>${Px.t('Color Palette')}</h2>
                  ${r(Dropdown, `color-palette-select-${element.unique_id}`, this.colorPaletteProps)}
                </div>
              `;
            })}

            ${Px.if(element.type === 'text', () => {
              return Px.template`
                <div class="px-edit-control">
                  <h2>${Px.t('Font Palette')}</h2>
                  ${r(Dropdown, `font-palette-select-${element.unique_id}`, this.fontPaletteProps)}
                </div>

                <div class="px-edit-control">
                  <h2>${Px.t('Vertical Align')}</h2>
                  ${r(Dropdown, `vertical-align-select-${element.unique_id}`, this.verticalAlignProps)}
                </div>

                <div class="px-edit-control">
                  <h2>${Px.t('Typography')}</h2>
                  <div class="px-typography-settings">
                    <div class="px-setting">
                      <span class="px-prop-name">${Px.t('kerning')}:</span>
                      <input type="number" step="any" value="${element.kerning}" data-onchange="setKerning" />
                    </div>
                    <div class="px-setting">
                      <span class="px-prop-name">${Px.t('leading')}:</span>
                      <input type="number" step="any" value="${element.leading}" data-onchange="setLeading" />
                    </div>
                  </div>
                </div>

                <div class="px-edit-control">
                  <h2>${Px.t('Paddings')}</h2>
                  <div class="px-padding-settings">
                    <div class="px-setting">
                      <span class="px-prop-name">${Px.t('top')}:</span>
                      <input type="number" step="any" value="${element.tp}" data-onchange="setTopPadding" />
                    </div>
                    <div class="px-setting">
                      <span class="px-prop-name">${Px.t('bottom')}:</span>
                      <input type="number" step="any" value="${element.bp}" data-onchange="setBottomPadding" />
                    </div>
                    <div class="px-setting">
                      <span class="px-prop-name">${Px.t('left')}:</span>
                      <input type="number" step="any" value="${element.lp}" data-onchange="setLeftPadding" />
                    </div>
                    <div class="px-setting">
                      <span class="px-prop-name">${Px.t('right')}:</span>
                      <input type="number" step="any" value="${element.rp}" data-onchange="setRightPadding" />
                    </div>
                  </div>
                </div>

                ${Px.if(Px.urlQuery().showpath === 't', () => {
                  return Px.template`
                    <div class="px-edit-control">
                      <h2>${Px.t('Text Path')}</h2>
                      <textarea class="px-text-path" data-onchange="setTextPath">${element.path}</textarea>
                    </div>
                  `;
                })}
              `;
            })}

            ${Px.if(store.theme.pdf_layers.length, () => {
              return Px.template`
                <div class="px-edit-control">
                  <h2>${Px.t('PDF Layer')}</h2>
                  ${r(Dropdown, `pdf-layer-select-${element.unique_id}`, this.pdfLayerDropdownProps)}
                </div>
              `;
            })}

            ${Px.if(['image', 'text', 'ipage', 'qrcode'].includes(element.type), () => {
              return Px.template`
                <div class="px-edit-control">
                  <h2>${Px.t('Element Name')}</h2>
                  <input class="px-element-name" type="text" data-onchange="setName" value="${element.name || ''}" />
                </div>
              `;
            })}
          </div>
        </div>
      </div>
    `;
  }

  constructor(data) {
    super(data);

    this.setEdit = this.setEdit.bind(this);
    this.setMove = this.setMove.bind(this);
    this.setResize = this.setResize.bind(this);
    this.setDelete = this.setDelete.bind(this);
    this.setRotate = this.setRotate.bind(this);
    this.setOpacity = this.setOpacity.bind(this);
    this.setLayers = this.setLayers.bind(this);
    this.setBorder = this.setBorder.bind(this);
    this.setBorderColor = this.setBorderColor.bind(this);
    this.setBorderRadius = this.setBorderRadius.bind(this);
    this.setMask = this.setMask.bind(this);
    this.setReplace = this.setReplace.bind(this);
    this.setFontSize = this.setFontSize.bind(this);
    this.setColor = this.setColor.bind(this);
    this.setShrink = this.setShrink.bind(this);
    this.setCrop = this.setCrop.bind(this);
    this.setStretch = this.setStretch.bind(this);
    this.setPlaceholder = this.setPlaceholder.bind(this);
    this.setShowOnPreview = this.setShowOnPreview.bind(this);
    this.setColorPalette = this.setColorPalette.bind(this);
    this.setFontPalette = this.setFontPalette.bind(this);
    this.setVerticalAlign = this.setVerticalAlign.bind(this);
    this.setKerning = this.setKerning.bind(this);
    this.setLeading = this.setLeading.bind(this);
    this.setTopPadding = this.setTopPadding.bind(this);
    this.setBottomPadding = this.setBottomPadding.bind(this);
    this.setLeftPadding = this.setLeftPadding.bind(this);
    this.setRightPadding = this.setRightPadding.bind(this);
    this.setTextPath = this.setTextPath.bind(this);
    this.setZLayer = this.setZLayer.bind(this);
    this.setPdfLayer = this.setPdfLayer.bind(this);
    this.onBeforeLayerSliderDrag = this.onBeforeLayerSliderDrag.bind(this);
    this.onAfterLayerSliderDrag = this.onAfterLayerSliderDrag.bind(this);
  }

  get dataProperties() {
    return {
      element: {required: true},
      store: {required: true}
    };
  }

  static get computedProperties() {
    return {
      editButtonProps: function() {
        return {
          content: Px.t('Editable'),
          pressed: this.data.element.edit,
          onToggle: this.setEdit
        };
      },
      moveButtonProps: function() {
        return {
          content: Px.t('Move'),
          pressed: this.data.element.move,
          onToggle: this.setMove
        };
      },
      resizeButtonProps: function() {
        return {
          content: Px.t('Resize'),
          pressed: this.data.element.resize,
          onToggle: this.setResize
        };
      },
      deleteButtonProps: function() {
        return {
          content: Px.t('Delete'),
          pressed: this.data.element.delete,
          onToggle: this.setDelete
        };
      },
      rotateButtonProps: function() {
        return {
          content: Px.t('Rotate'),
          pressed: this.data.element.erotation,
          onToggle: this.setRotate
        };
      },
      opacityButtonProps: function() {
        return {
          content: Px.t('Opacity'),
          pressed: this.data.element.eopacity,
          onToggle: this.setOpacity
        }
      },
      layersButtonProps: function() {
        return {
          content: Px.t('Layers'),
          pressed: this.data.element.elayer,
          onToggle: this.setLayers
        };
      },
      borderButtonProps: function() {
        return {
          content: Px.t('Border'),
          pressed: this.data.element.eborder,
          onToggle: this.setBorder
        };
      },
      borderColorButtonProps: function() {
        return {
          content: Px.t('Border Color'),
          pressed: this.data.element.ebordercolor,
          onToggle: this.setBorderColor
        };
      },
      borderRadiusButtonProps: function() {
        return {
          content: Px.t('Border Radius'),
          pressed: this.data.element.eborderradius,
          onToggle: this.setBorderRadius
        };
      },
      maskButtonProps: function() {
        return {
          content: Px.t('Mask'),
          pressed: this.data.element.emask,
          onToggle: this.setMask
        };
      },
      replaceButtonProps: function() {
        return {
          content: Px.t('Replace'),
          pressed: this.data.element.replace,
          onToggle: this.setReplace
        };
      },
      fontSizeButtonProps: function() {
        return {
          content: Px.t('Font Size'),
          pressed: this.data.element.efontsize,
          onToggle: this.setFontSize
        };
      },
      colorButtonProps: function() {
        return {
          content: Px.t('Color'),
          pressed: this.data.element.ecolor,
          onToggle: this.setColor
        };
      },
      shrinkButtonProps: function() {
        return {
          content: Px.t('Shrink'),
          pressed: this.data.element.shrink,
          onToggle: this.setShrink
        };
      },
      cropButtonProps: function() {
        return {
          content: Px.t('Crop'),
          pressed: this.data.element.crop,
          onToggle: this.setCrop
        }
      },
      stretchButtonProps: function() {
        return {
          content: Px.t('Stretch'),
          pressed: this.data.element.stretch,
          onToggle: this.setStretch
        };
      },
      placeholderButtonProps: function() {
        return {
          content: Px.t('Placeholder'),
          pressed: this.data.element.placeholder,
          onToggle: this.setPlaceholder
        };
      },
      showOnPreviewButtonProps: function() {
        return {
          content: Px.t('Show on Preview'),
          pressed: this.data.element.show_on_preview,
          onToggle: this.setShowOnPreview
        };
      },
      xInputProps: function() {
        return {
          property: 'x',
          element: this.data.element,
          store: this.data.store
        };
      },
      yInputProps: function() {
        return {
          property: 'y',
          element: this.data.element,
          store: this.data.store
        };
      },
      widthInputProps: function() {
        return {
          property: 'width',
          element: this.data.element,
          store: this.data.store,
          min: 0
        };
      },
      heightInputProps: function() {
        return {
          property: 'height',
          element: this.data.element,
          store: this.data.store,
          min: 0
        };
      },
      colorPaletteProps: function() {
        const options = [{value: null, name: ''}];
        this.data.store.color_palettes.palettes.forEach(palette => {
          options.push({
            value: palette.id,
            name: palette.name
          });
        });
        return {
          value: this.data.element.palette,
          options: options,
          onNewValue: this.setColorPalette
        };
      },
      fontPaletteProps: function() {
        const options = [{value: null, name: ''}];
        this.data.store.font_palettes.palettes.forEach(palette => {
          options.push({
            value: palette.id,
            name: palette.name
          });
        });
        return {
          value: this.data.element.fontpalette,
          options: options,
          onNewValue: this.setFontPalette
        };
      },
      verticalAlignProps: function() {
        return {
          value: this.data.element.valign,
          options: [
            {value: 'top', name: Px.t('Top')},
            {value: 'center', name: Px.t('Middle')},
            {value: 'bottom', name: Px.t('Bottom')}
          ],
          onNewValue: this.setVerticalAlign
        }
      },
      layerSliderProps: function() {
        return {
          min: Px.Editor.BaseElementModel.MIN_Z_VALUE,
          max: Px.Editor.BaseElementModel.MAX_Z_VALUE,
          value: this.data.element.z,
          onNewValue: this.setZLayer,
          onBeforeDrag: this.onBeforeLayerSliderDrag,
          onAfterDrag: this.onAfterLayerSliderDrag
        };
      },
      borderwrapInputProps: function() {
        return {
          property: 'borderwrap',
          show_label: false,
          element: this.data.element,
          store: this.data.store,
          min: 0
        };
      },
      pdfLayerDropdownProps: function() {
        const options = [{value: null, name: ''}];
        this.data.store.theme.pdf_layers.forEach(layer => {
          options.push({
            value: layer.name,
            name: layer.name
          });
        });
        return {
          value: this.data.element.pdf_layer,
          options: options,
          onNewValue: this.setPdfLayer
        };
      },
    };
  }

  setEdit(edit) {
    this.withUndo('set edit flag', () => {
      this.data.element.update({edit: edit});
    });
  }
  setMove(move) {
    this.withUndo('set move flag', () => {
      this.data.element.update({move: move});
    });
  }
  setResize(resize) {
    this.withUndo('set resize flag', () => {
      this.data.element.update({resize: resize});
    });
  }
  setDelete(del) {
    this.withUndo('set delete flag', () => {
      this.data.element.update({'delete': del});
    });
  }
  setRotate(rotate) {
    this.withUndo('set rotate flag', () => {
      this.data.element.update({erotation: rotate});
    });
  }
  setOpacity(opacity) {
    this.withUndo('set opacity flag', () => {
      this.data.element.update({eopacity: opacity});
    });
  }
  setLayers(layers) {
    this.withUndo('set layers flag', () => {
      this.data.element.update({elayer: layers});
    });
  }
  setBorder(border) {
    this.withUndo('set border flag', () => {
      this.data.element.update({eborder: border});
    });
  }
  setBorderColor(bordercolor) {
    this.withUndo('set border color flag', () => {
      this.data.element.update({ebordercolor: bordercolor});
    });
  }
  setBorderRadius(borderradius) {
    this.withUndo('set border radius flag', () => {
      this.data.element.update({eborderradius: borderradius});
    });
  }
  setMask(mask) {
    this.withUndo('set mask flag', () => {
      this.data.element.update({emask: mask});
    });
  }
  setReplace(replace) {
    this.withUndo('set replace flag', () => {
      this.data.element.update({replace: replace});
    });
  }
  setFontSize(fontsize) {
    this.withUndo('set font size flag', () => {
      this.data.element.update({efontsize: fontsize});
    });
  }
  setColor(color) {
    this.withUndo('set color flag', () => {
      this.data.element.update({ecolor: color});
    });
  }
  setShrink(shrink) {
    this.withUndo('set shrink flag', () => {
      this.data.element.update({shrink: shrink});
    });
  }
  setCrop(crop) {
    this.withUndo('set crop flag', () => {
      this.data.element.update({crop: crop});
    });
  }
  setStretch(stretch) {
    this.withUndo('set stretch flag', () => {
      this.data.element.update({stretch: stretch});
    });
  }
  setPlaceholder(placeholder) {
    this.withUndo('set placeholder flag', () => {
      this.data.element.update({placeholder: placeholder});
    });
  }
  setShowOnPreview(show) {
    this.withUndo('set show on preview flag', () => {
      this.data.element.update({show_on_preview: show});
    });
  }
  setColorPalette(palette_id) {
    this.withUndo('set color palette', () => {
      this.data.element.update({palette: palette_id});
    });
  }
  setFontPalette(palette_id) {
    this.withUndo('set font palette', () => {
      this.data.element.update({fontpalette: palette_id});
    });
  }
  setVerticalAlign(valign) {
    this.withUndo('set vertical align', () => {
      this.data.element.update({valign: valign});
    });
  }
  setZLayer(layer) {
    this.data.element.update({z: layer});
  }
  setPdfLayer(pdf_layer) {
    this.data.element.update({pdf_layer: pdf_layer});
  }

  onBeforeLayerSliderDrag() {
    this.beginWithUndo('change element layer');
  }
  onAfterLayerSliderDrag() {
    this.endWithUndo('change element layer');
  }

  // --------------
  // Event handlers
  // --------------

  setName(evt) {
    const val = evt.target.value.trim();
    this.withUndo('set element name', () => {
      this.data.element.name = val || null;
    });
  }
  setKerning(evt) {
    const val = parseFloat(evt.target.value);
    this.withUndo('set kerning', () => {
      this.data.element.update({kerning: val});
    });
  }
  setLeading(evt) {
    const val = parseFloat(evt.target.value);
    this.withUndo('set leading', () => {
      this.data.element.update({leading: val});
    });
  }
  setTopPadding(evt) {
    const val = parseFloat(evt.target.value);
    this.withUndo('set top padding', () => {
      this.data.element.update({tp: val});
    });
  }
  setBottomPadding(evt) {
    const val = parseFloat(evt.target.value);
    this.withUndo('set bottom padding', () => {
      this.data.element.update({bp: val});
    });
  }
  setLeftPadding(evt) {
    const val = parseFloat(evt.target.value);
    this.withUndo('set left padding', () => {
      this.data.element.update({lp: val});
    });
  }
  setRightPadding(evt) {
    const val = parseFloat(evt.target.value);
    this.withUndo('set right padding', () => {
      this.data.element.update({rp: val});
    });
  }
  setTextPath(evt) {
    const val = evt.target.value.trim();
    this.withUndo('set text path', () => {
      this.data.element.update({path: val || null});
    });
  }

  ungroupElement(evt) {
    const store = this.data.store;
    const element = this.data.element;
    const group  = element.group;
    this.withUndo('ungroup element', () => {
      store.ungroupElement(element);
      // If after ungrouping this element, only one other element is left in the group,
      // ungroup it as well and destroy the group, since groups with one element don't
      // make much sense.
      if (group.elements.length <= 1) {
        group.elements.forEach(element => {
          store.ungroupElement(element);
        });
        group.destroy();
      }
    });
  }

};
